<template>
  <div id="possibilities">
    <mainheader></mainheader>
    <ChooseTariffModal></ChooseTariffModal>
    <mainbox></mainbox>
    <section>
      <div class="Collapse-bar">
        <div class="container">

          <!-- <div >
            <div class="mx-2"  style="display: flex; flex-direction: row; justify-content: space-between;">
              <div style="" v-for="item in redirects.slice(0, 3)" :key="item">
                <p class="article-text article-text-bold mb-2">{{ item.title }}</p>
                <div style="display: flex; flex-direction: column; justify-content: start; align-items: start;">
                  <button @click="redirectUrl(url.url)" class="dropbtn mt-2" v-for="url in item.items" :key="url">{{
                    url.title }}</button>
                </div>
              </div>
            </div>
          </div>
          <div >
            <div class="mx-2"  style="display: flex; flex-direction: row; justify-content: space-between;">
              <div style="" v-for="item in redirects.slice(3, 6)" :key="item">
                <p class="article-text article-text-bold mb-2">{{ item.title }}</p>
                <div style="display: flex; flex-direction: column; justify-content: start; align-items: start;">
                  <button @click="redirectUrl(url.url)" class="dropbtn mt-2" v-for="url in item.items" :key="url">{{
                    url.title }}</button>
                </div>
              </div>
            </div>
          </div>
          <div >
            <div class="mx-2"  style="display: flex; flex-direction: row; justify-content: space-between;">
              <div style="" v-for="item in redirects.slice(6, 9)" :key="item">
                <p class="article-text article-text-bold mb-2">{{ item.title }}</p>
                <div style="display: flex; flex-direction: column; justify-content: start; align-items: start;">
                  <button @click="redirectUrl(url.url)" class="dropbtn mt-2" v-for="url in item.items" :key="url">{{
                    url.title }}</button>
                </div>
              </div>
            </div>
          </div>
          <div >
            <div class="mx-2"  style="display: flex; flex-direction: row; justify-content: space-between;">
              <div style="" v-for="item in redirects.slice(9, 11)" :key="item">
                <p class="article-text article-text-bold mb-2">{{ item.title }}</p>
                <div style="display: flex; flex-direction: column; justify-content: start; align-items: start;">
                  <button @click="redirectUrl(url.url)" class="dropbtn mt-2" v-for="url in item.items" :key="url">{{
                    url.title }}</button>
                </div>
              </div>
            </div>
          </div> -->


          <div class="possibility-container">
            <div class="mb-5" style="width: 90%;">
              <h1 class="mb-5">Возможности</h1>
              <div style="display: flex; flex-direction: row; justify-content: space-between; flex-wrap: wrap;">
                <div class="mx-2" style="display: inline-block;">
                  <div class="mb-5" v-for="item in redirects.slice(0, 4)" :key="item">
                    <p class="article-text article-text-bold mb-0">{{ item.title }}</p>
                    <div style="display: flex; flex-direction: column; justify-content: start; align-items: start;">
                      <button @click="redirectUrl(url.url)" class="color-green dropbtn mt-2" v-for="url in item.items"
                        :key="url">{{
                          url.title }}</button>
                    </div>
                  </div>
                </div>
                <div class="mx-2" style="display: inline-block;">
                  <div class="mb-5" v-for="item in redirects.slice(4, 8)" :key="item">
                    <p class="article-text article-text-bold mb-0">{{ item.title }}</p>
                    <div style="display: flex; flex-direction: column; justify-content: start; align-items: start;">
                      <button @click="redirectUrl(url.url)" class="color-green  dropbtn mt-2" v-for="url in item.items"
                        :key="url">{{
                          url.title }}</button>
                    </div>
                  </div>
                </div>
                <div class="mx-2" style="display: inline-block;">
                  <div class="mb-5" v-for="item in redirects.slice(8, 11)" :key="item">
                    <p class="article-text article-text-bold mb-0">{{ item.title }}</p>
                    <div style="display: flex; flex-direction: column; justify-content: start; align-items: start;">
                      <button @click="redirectUrl(url.url)" class="color-green  dropbtn mt-2" v-for="url in item.items"
                        :key="url">{{
                          url.title }}</button>
                      <button id="hiddenbtn" class="dropbtn mt-2" style="cursor:default;"
                        v-if="item.items[0].url == '8.0'">&nbsp;</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="accordion mt-5" id="accordionExample"> 
              <div class="row">
                <div class="col-lg-6">
                      <div class="accordion-item item-acor border-pos col-lg-12">
                        <h2 class="accordion-header padding-none border-pos span-possibilities" data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                            <button class="accordion-button item-acor-header" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                              <img src="../assets/images/Icons-Possibilities/i1.png" class="mr-5-5" alt="png"> <span>{{ translation.translate ('Possibilities','adding-title') }}</span>
                            </button>
                          </h2>
                          <div id="collapseExample" class="accordion-collapse collapse show">
                            <div class="accordion-body">
                              <p class="light">{{ translation.translate ('Possibilities','creating-info') }}</p>
                              <ul>
                                <li>{{ translation.translate ('Possibilities','creating-info-1') }}</li>
                                <li>{{ translation.translate ('Possibilities','creating-info-2') }}</li>
                                <li>{{ translation.translate ('Possibilities','creating-info-3') }}</li>
                                <li>{{ translation.translate ('Possibilities','creating-info-4') }}</li>
                                <li>{{ translation.translate ('Possibilities','creating-info-5') }}</li>
                                <li>{{ translation.translate ('Possibilities','creating-info-6') }}</li>
                                <li>{{ translation.translate ('Possibilities','creating-info-7') }}</li>
                                <li>{{ translation.translate ('Possibilities','creating-info-8') }}</li>
                                <li>{{ translation.translate ('Possibilities','creating-info-9') }}</li>
                                <li>{{ translation.translate ('Possibilities','creating-info-10') }}</li>
                                <li>{{ translation.translate ('Possibilities','creating-info-11') }}</li>
                                <li>{{ translation.translate ('Possibilities','creating-info-12') }}</li>
                                <li>{{ translation.translate ('Possibilities','creating-info-13') }}</li>
                            </ul>
                            <p class="light">
                              {{ translation.translate ('Possibilities','creating-info-14') }}
                          </p>   
                          <p class="light">
                              {{ translation.translate ('Possibilities','creating-info-15') }}
                          </p>    
                          <p class="light creating-info-16">
                              {{ translation.translate ('Possibilities','creating-info-16') }} 
                          </p>                          
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item item-acor border-pos col-lg-12">
                          <h2 class="accordion-header padding-none border-pos span-possibilities" data-toggle="collapse" href="#collapseExample1" role="button" aria-expanded="false" aria-controls="collapseExample1">
                            <button class="accordion-button item-acor-header collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                              <img src="../assets/images/Icons-Possibilities/i2.png" class="mr-5-5" alt="png"> <span> {{ translation.translate ('Possibilities','approval-title') }}</span>
                            </button>
                          </h2>
                          <div id="collapseExample1" class="accordion-collapse collapse">
                            <div class="accordion-body">
                              <p class="light">
                                {{ translation.translate ('Possibilities','approval-1') }}
                            </p>
                            <p class="light">
                                {{ translation.translate ('Possibilities','approval-2') }}
                            </p>
                            <p class="light">
                                <span class="font-weight-bolder dark">{{ translation.translate ('Possibilities','approval-3') }}</span> 
                            </p>
                            <p class="light">
                                <span class="font-weight-bolder dark">{{ translation.translate ('Possibilities','approval-4') }}</span>
                            </p>
                            <p class="light">
                                <span class="font-weight-bolder dark">{{ translation.translate ('Possibilities','approval-5') }} </span>
                            </p>
                            <a class="facilities-vid" target="_blank" href="https://youtu.be/MWdhfXCkRh8">
                                <img src="../assets/images/video images/sogldocvide.png" >
                            </a>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item item-acor border-pos col-lg-12">
                          <h2 class="accordion-header padding-none border-pos span-possibilities" data-toggle="collapse" href="#collapseExample2" role="button" aria-expanded="false" aria-controls="collapseExample2">
                            <button class="accordion-button item-acor-header collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                              <img src="../assets/images/Icons-Possibilities/i3.png" class="mr-5-5" alt="png"> <span> {{ translation.translate ('Possibilities','Storage-title') }}</span>
                            </button>
                          </h2>
                          <div id="collapseExample2" class="accordion-collapse collapse">
                            <div class="accordion-body">
                              <p class="light">
                                {{ translation.translate ('Possibilities','Storage-1') }}
                            </p>
                            <p class="light">
                                <span class="font-weight-bodler">Archieve manager</span> <span>{{ translation.translate ('Possibilities','Storage-2') }}</span>
                            </p>
                            <a class="facilities-vid" target="_blank" href="https://youtu.be/2YJi3hKGGzk">
                                <img src="../assets/images/video images/ArchiveVide.png" >
                            </a>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item item-acor border-pos col-lg-12">
                            <h2 class="accordion-header padding-none border-pos span-possibilities" data-toggle="collapse" href="#collapseFour" role="button" aria-expanded="false" aria-controls="collapseFour">
                              <button class="accordion-button item-acor-header collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                <img src="../assets/images/Icons-Possibilities/i4.png" class="mr-5-5" alt="png"><span>{{ translation.translate ('Possibilities','Filtration-title') }}</span>
                              </button>
                            </h2>
                            <div id="collapseFour" class="accordion-collapse collapse">
                              <div class="accordion-body">
                                <p class="light">
                                  {{ translation.translate ('Possibilities','Filtration-1') }}                                     
                              </p>
                              <p class="light">
                                  {{ translation.translate ('Possibilities','Filtration-2') }}
                              </p>
                              <ul>
                                  <li>{{ translation.translate ('Possibilities','Filtration-3') }}</li>
                                  <li>{{ translation.translate ('Possibilities','Filtration-4') }}</li>
                                  <li>{{ translation.translate ('Possibilities','Filtration-5') }}</li>
                                  <li>{{ translation.translate ('Possibilities','Filtration-6') }}</li>
                                  <li>{{ translation.translate ('Possibilities','Filtration-7') }}</li>
                                  <li>{{ translation.translate ('Possibilities','Filtration-8') }}</li>
                                  <li>{{ translation.translate ('Possibilities','Filtration-9') }}</li>
                              </ul>
                              <p class="light">
                                  {{ translation.translate ('Possibilities','Filtration-10') }}
                              </p>
                              <a class="facilities-vid" target="_blank" href="https://youtu.be/RzFrlnP-XyQ">
                                  <img src="../assets/images/video images/filtrvid.png" >
                              </a>
                              </div>
                            </div>
                          </div>
                        <div class="accordion-item item-acor border-pos col-lg-12">
                            <h2 class="accordion-header padding-none border-pos span-possibilities" data-toggle="collapse" href="#collapseFive" role="button" aria-expanded="false" aria-controls="collapseFive">
                              <button class="accordion-button item-acor-header collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                <img src="../assets/images/Icons-Possibilities/i5.png" class="mr-5-5" alt="png"><span>{{ translation.translate ('Possibilities','Subdivisions-title') }}</span>
                              </button>
                            </h2>
                            <div id="collapseFive" class="accordion-collapse collapse">
                              <div class="accordion-body">
                                <p class="light">
                                  {{ translation.translate ('Possibilities','Subdivisions-info') }}
                              </p>
                              </div>
                            </div>
                          </div>
                        <div class="accordion-item item-acor border-pos col-lg-12">
                            <h2 class="accordion-header padding-none border-pos span-possibilities" data-toggle="collapse" href="#collapseSix" role="button" aria-expanded="false" aria-controls="collapseSix">
                              <button class="accordion-button item-acor-header collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                <img src="../assets/images/Icons-Possibilities/i7.png" class="mr-5-5" alt="png"> <span> {{ translation.translate ('Possibilities','Employees-title') }} </span>
                              </button>
                            </h2>
                            <div id="collapseSix" class="accordion-collapse collapse">
                              <div class="accordion-body">
                                <p class="light Employees-1">
                                  {{ translation.translate ('Possibilities','Employees-1') }}
                              </p>
                              <a class="facilities-vid" target="_blank" href="https://youtu.be/Mj34weqNj9g">
                                   <img src="../assets/images/video images/sotrbide.png" >
                               </a>
                              </div>
                            </div>
                          </div>
                        <div class="accordion-item item-acor border-pos col-lg-12">
                            <h2 class="accordion-header padding-none border-pos span-possibilities" data-toggle="collapse" href="#collapseSeven" role="button" aria-expanded="false" aria-controls="collapseSeven">
                              <button class="accordion-button item-acor-header collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                <img src="../assets/images/Icons-Possibilities/i8.png" class="mr-5-5" alt="png"><span>{{ translation.translate ('Possibilities','RouminG') }}</span>
                              </button>
                            </h2>
                            <div id="collapseSeven" class="accordion-collapse collapse">
                              <div class="accordion-body">
                                <p class="light">
                                 {{ translation.translate ('Possibilities','rouming-info') }}
                              </p>
                              </div>
                            </div>
                          </div>
                        <div class="accordion-item item-acor border-pos col-lg-12">
                            <h2 class="accordion-header padding-none border-pos span-possibilities" data-toggle="collapse" href="#collapseEight" role="button" aria-expanded="false" aria-controls="collapseEight">
                              <button class="accordion-button item-acor-header collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                <img src="../assets/images/Icons-Possibilities/i9.png" class="mr-5-5" alt="png"> <span>{{ translation.translate ('Possibilities','Security-title') }}</span>
                              </button>
                            </h2>
                            <div id="collapseEight" class="accordion-collapse collapse">
                              <div class="accordion-body">
                                <p class="light">{{ translation.translate ('Possibilities','Security-info') }}</p>
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item item-acor border-pos col-lg-12">
                            <h2 class="accordion-header padding-none border-pos span-possibilities" data-toggle="collapse" href="#collapseNine" role="button" aria-expanded="false" aria-controls="collapseNine">
                              <button class="accordion-button item-acor-header collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                <img src="../assets/images/Icons-Possibilities/i10.png" class="mr-5-5" alt="png"><span>{{ translation.translate ('Possibilities','Protocol-title') }}</span>
                              </button>
                            </h2>
                            <div id="collapseNine" class="accordion-collapse collapse">
                              <div class="accordion-body">
                                <p class="light">{{ translation.translate ('Possibilities','Protocol-info') }}</p>
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item item-acor border-pos col-lg-12">
                            <h2 class="accordion-header padding-none border-pos span-possibilities" data-toggle="collapse" href="#collapse10" role="button" aria-expanded="false" aria-controls="collapse10">
                              <button class="accordion-button item-acor-header collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse10" aria-expanded="false" aria-controls="collapse10">
                                <img src="../assets/images/Icons-Possibilities/i11.png" class="mr-5-5" alt="png"> <span>{{ translation.translate ('Possibilities','modul-title') }}</span>
                              </button>
                            </h2>
                            <div id="collapse10" class="accordion-collapse collapse" aria-labelledby="heading10" data-bs-parent="#accordionExample">
                              <div class="accordion-body">
                                <p class="light">{{ translation.translate ('Possibilities','modul-1') }}</p>
                                <p class="light mb-0">
                                     <span class="dark font-weight-bolder">1С модуль</span> <span>{{ translation.translate ('Possibilities','modul-2') }}</span>
                                </p>
                                <a class="link-insode-facilities mb-3" target="_blank" href="https://t.me/Salokhiddin_Sattarov">{{ translation.translate ('Possibilities','modul-3') }}</a>


                                <p class="light mb-0">
                                     {{ translation.translate ('Possibilities','modul-4') }}
                                </p>
                                <a class="link-insode-facilities mb-1" target="_blank" href="https://edo.faktura.uz/recources/Archive Manager.msi" download="Archive Manager">{{ translation.translate ('Resources','download-btn') }}</a>
                                 <p class="light mb-0">
                                     {{ translation.translate ('Possibilities','modul-5') }}
                                </p>
                                <a class="link-insode-facilities mb-1" target="_blank" href="https://edo.faktura.uz/recources/Faktura-Sync.zip" download="Faktura-Sync">Скачать</a>
                                <p class="light mb-0">
                                     <span class="dark font-weight-bolder">HelpDesk </span>  {{ translation.translate ('Possibilities','modul-6') }}
                                </p>
                                <a class="link-insode-facilities mb-1" target="_blank" href="https://app.botcrm.uz/app/user/dashboard">Попробовать</a>
                                 <p class="light mb-0">
                                     <span class="dark font-weight-bolder">HelpDesk Telegram Bot </span> {{ translation.translate ('Possibilities','modul-7') }}
                                </p>
                                <a class="link-insode-facilities" target="_blank" href="https://app.botcrm.uz/app/user/dashboard">{{ translation.translate ('LandingPageHome','btnTry') }}</a>
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item item-acor border-pos col-lg-12">
                            <h2 class="accordion-header padding-none border-pos span-possibilities" data-toggle="collapse" href="#collapse11" role="button" aria-expanded="false" aria-controls="collapse11">
                              <button class="accordion-button item-acor-header collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse11" aria-expanded="false" aria-controls="collapse11">
                                <img src="../assets/images/Icons-Possibilities/i12.png" class="mr-5-5" alt="png"><span>{{ translation.translate ('Possibilities','Routing-title') }}</span>
                              </button>
                            </h2>
                            <div id="collapse11" class="accordion-collapse collapse">
                              <div class="accordion-body">
                                <p class="light">{{ translation.translate ('Possibilities','Routing-1') }}</p>
                                   <p class="light">
                                        {{ translation.translate ('Possibilities','Routing-2') }}
                                   </p>
                                   <p class="light">
                                        {{ translation.translate ('Possibilities','Routing-3') }}.
                                   </p>
                                    <p class="light">
                                        {{ translation.translate ('Possibilities','Routing-4') }}
                                   </p>
                                   <a class="facilities-vid" target="_blank" href="https://youtu.be/qIkGnyFvCSU">
                                        <img src="../assets/images/video images/sortdocs.png" >
                                    </a>
                              </div>
                            </div>
                          </div>
                            </div>
                          <div class="col-lg-6">
                            <div class="accordion-item item-acor border-pos col-lg-12">
                            <h2 class="accordion-header padding-none border-pos span-possibilities" data-toggle="collapse" href="#collapse12" role="button" aria-expanded="false" aria-controls="collapse12">
                              <button class="accordion-button item-acor-header collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse12" aria-expanded="false" aria-controls="collapse12">
                                <img src="../assets/images/Icons-Possibilities/i13.png" class="mr-5-5" alt="png"><span>{{ translation.translate ('Possibilities','Alerts-title') }}</span>
                              </button>
                            </h2>
                            <div id="collapse12" class="accordion-collapse collapse">
                              <div class="accordion-body">
                                <p class="light">{{ translation.translate ('Possibilities','Alerts-1') }}</p>
                                <p class="light">
                                     {{ translation.translate ('Possibilities','Alerts-2') }}
                                </p>
                                <p class="light">
                                     {{ translation.translate ('Possibilities','Alerts-3') }} 
                                </p>
                                 <p class="light">
                                     {{ translation.translate ('Possibilities','Alerts-4') }}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item item-acor border-pos col-lg-12">
                            <h2 class="accordion-header padding-none border-pos span-possibilities" data-toggle="collapse" href="#collapse13" role="button" aria-expanded="false" aria-controls="collapse13">
                              <button class="accordion-button item-acor-header collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse13" aria-expanded="false" aria-controls="collapse13">
                                <img src="../assets/images/Icons-Possibilities/i14.png" class="mr-5-5" alt="png"><span>{{ translation.translate ('Possibilities','chanel-title') }}</span>
                              </button>
                            </h2>
                            <div id="collapse13" class="accordion-collapse collapse">
                              <div class="accordion-body">
                                <p class="light">{{ translation.translate ('Possibilities','chanel-1') }}</p>
                                <ul>
                                    <li>Web</li>
                                    <li>API (1C, SAP, 3-е сторонние ПО)</li>
                                    <li>API (Индивидуальный, стандартный)</li>
                                </ul>
                                <p class="light">{{ translation.translate ('Possibilities','chanel-2') }} </p>
                                <a class="link-insode-facilities" target="_blank" href="https://t.me/Salokhiddin_Sattarov">{{ translation.translate ('Possibilities','modul-3') }}</a>
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item item-acor border-pos col-lg-12">
                            <h2 class="accordion-header padding-none border-pos span-possibilities" data-toggle="collapse" href="#collapse14" role="button" aria-expanded="false" aria-controls="collapse14">
                              <button class="accordion-button item-acor-header collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse14" aria-expanded="false" aria-controls="collapse14">
                                <img src="../assets/images/Icons-Possibilities/i15.png" class="mr-5-5" alt="png"><span>{{ translation.translate ('Possibilities','Report-title') }}</span>
                              </button>
                            </h2>
                            <div id="collapse14" class="accordion-collapse collapse">
                              <div class="accordion-body">
                                <p class="light">
                                  <span class="dark font-weight-bolder"></span>{{ translation.translate ('Possibilities','Report-1') }}
                             </p>
                             <p class="light">
                                  {{ translation.translate ('Possibilities','Report-2') }}
                             </p>
                             <p class="light">
                                   {{ translation.translate ('Possibilities','Report-3') }} 
                             </p>
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item item-acor border-pos col-lg-12">
                            <h2 class="accordion-header padding-none border-pos span-possibilities" data-toggle="collapse" href="#collapse16" role="button" aria-expanded="false" aria-controls="collapse16">
                              <button class="accordion-button item-acor-header collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse16" aria-expanded="false" aria-controls="collapse16">
                                <img src="../assets/images/Icons-Possibilities/i16.png" class="mr-5-5" alt="png"><span>{{ translation.translate ('Possibilities','template-title') }}</span>
                              </button>
                            </h2>
                            <div id="collapse16" class="accordion-collapse collapse">
                              <div class="accordion-body">
                                <p class="light">
                                  {{ translation.translate ('Possibilities','template-1') }}
                             </p>
                              <a class="link-insode-facilities" target="_blank" href="https://t.me/Salokhiddin_Sattarov">{{ translation.translate ('Possibilities','modul-3') }}</a> 
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item item-acor border-pos col-lg-12">
                            <h2 class="accordion-header padding-none border-pos span-possibilities" data-toggle="collapse" href="#collapse17" role="button" aria-expanded="false" aria-controls="collapse17">
                              <button class="accordion-button item-acor-header collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse17" aria-expanded="false" aria-controls="collapse17">
                                <img src="../assets/images/Icons-Possibilities/i17.png" class="mr-5-5" alt="png"><span>{{ translation.translate ('Possibilities','metka-title') }}</span>
                              </button>
                            </h2>
                            <div id="collapse17" class="accordion-collapse collapse">
                              <div class="accordion-body">
                                <p class="light">
                                  {{ translation.translate ('Possibilities','metka-1') }}
                             </p>
                              <a class="facilities-vid" target="_blank" href="https://youtu.be/qIkGnyFvCSU">
                                  <img src="../assets/images/video images/sortdocs.png" >
                              </a>
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item item-acor border-pos col-lg-12">
                            <h2 class="accordion-header padding-none border-pos span-possibilities" data-toggle="collapse" href="#collapse18" role="button" aria-expanded="false" aria-controls="collapse18">
                              <button class="accordion-button item-acor-header collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse18" aria-expanded="false" aria-controls="collapse18">
                                <img src="../assets/images/Icons-Possibilities/i18.png" class="mr-5-5" alt="png"><span>{{ translation.translate ('Possibilities','monitoring-title') }}</span>
                              </button>
                            </h2>
                            <div id="collapse18" class="accordion-collapse collapse">
                              <div class="accordion-body">
                                <p class="light">
                                 {{ translation.translate ('Possibilities','monitoring-1') }}
                             </p>
                             <a class="link-insode-facilities" target="_blank" href="https://app.faktura.uz/ru/history/index#kpk">{{ translation.translate ('Possibilities','connect') }}</a>
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item item-acor border-pos col-lg-12">
                            <h2 class="accordion-header padding-none border-pos span-possibilities" data-toggle="collapse" href="#collapse19" role="button" aria-expanded="false" aria-controls="collapse19">
                              <button class="accordion-button item-acor-header collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="false" aria-controls="collapse1">
                                <img src="../assets/images/Icons-Possibilities/i19.png" class="mr-5-5" alt="png"><span>{{ translation.translate ('Possibilities','signing-title') }}</span>
                              </button>
                            </h2>
                            <div id="collapse19" class="accordion-collapse collapse">
                              <div class="accordion-body">
                                <p class="light">
                                  {{ translation.translate ('Possibilities','signing-1') }}
                             </p>
                             <a class="facilities-vid" target="_blank" href="https://youtu.be/VhvPhZIgzMM">
                                  <img src="../assets/images/video images/massgin.png" >
                              </a>
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item item-acor border-pos col-lg-12">
                            <h2 class="accordion-header padding-none border-pos span-possibilities" data-toggle="collapse" href="#collapse20" role="button" aria-expanded="false" aria-controls="collapse20">
                              <button class="accordion-button item-acor-header collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse20" aria-expanded="false" aria-controls="collapse20">
                                <img src="../assets/images/Icons-Possibilities/i20.png" class="mr-5-5" alt="png"><span>{{ translation.translate ('Possibilities','payment-title') }}</span>
                              </button>
                            </h2>
                            <div id="collapse20" class="accordion-collapse collapse">
                              <div class="accordion-body">
                                <p class="light">
                                  {{ translation.translate ('Possibilities','signing-1') }}
                             </p>
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item item-acor border-pos col-lg-12">
                            <h2 class="accordion-header padding-none border-pos span-possibilities" data-toggle="collapse" href="#collapse21" role="button" aria-expanded="false" aria-controls="collapse21">
                              <button class="accordion-button item-acor-header collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse21" aria-expanded="false" aria-controls="collapse21">
                                <img src="../assets/images/Icons-Possibilities/i21.png" class="mr-5-5" alt="png"><span >{{ translation.translate ('Possibilities','mobile-app-box') }}</span>
                              </button>
                            </h2>
                            <div id="collapse21" class="accordion-collapse collapse">
                              <div class="accordion-body">
                                <p class="light">
                                  {{ translation.translate ('Possibilities','mab-1') }}
                             </p>
                             <p class="light">
                                  {{ translation.translate ('Possibilities','mab-2') }}
                             </p>
                              <a class="link-insode-facilities" href="https://edo.faktura.uz/recources/Faktura Play Market.apk" download="Faktura Android.apk"><span>{{ translation.translate ('Resources','download-btn') }}</span> (Android)</a>
                              <br>
                             <a class="link-insode-facilities" target="_blank" href="https://apps.apple.com/us/app/faktura/id1454146398"><span>{{ translation.translate ('Resources','download-btn') }}</span> (IOS)</a>
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item item-acor border-pos col-lg-12">
                            <h2 class="accordion-header padding-none border-pos span-possibilities" data-toggle="collapse" href="#collapse22" role="button" aria-expanded="false" aria-controls="collapse22">
                              <button class="accordion-button item-acor-header collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse22" aria-expanded="false" aria-controls="collapse22">
                                <img src="../assets/images/Icons-Possibilities/i22.png" class="mr-5-5" alt="png"><span>{{ translation.translate ('Possibilities','mail-title') }}</span>
                              </button>
                            </h2>
                            <div id="collapse22" class="accordion-collapse collapse">
                              <div class="accordion-body">
                                <p class="light">
                                  {{ translation.translate ('Possibilities','mail-1') }}
                             </p>
                              <a class="facilities-vid" target="_blank" href="https://youtu.be/z4VPRwUx3k8">
                                  <img src="../assets/images/video images/hybpo.png" >
                              </a>
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item item-acor border-pos col-lg-12">
                            <h2 class="accordion-header padding-none border-pos span-possibilities" data-toggle="collapse" href="#collapse23" role="button" aria-expanded="false" aria-controls="collapse23">
                              <button class="accordion-button item-acor-header collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse23" aria-expanded="false" aria-controls="collapse23">
                                <img src="../assets/images/Icons-Possibilities/i23.png" class="mr-5-5" alt="png"><span>{{ translation.translate ('Possibilities','modul-features') }} </span>  1С Faktura Banking
                              </button>
                            </h2>
                            <div id="collapse23" class="accordion-collapse collapse">
                              <div class="accordion-body">
                                <p class="light">
                                 {{ translation.translate ('Possibilities','modul-info1c') }}
                             </p>
                              <a class="link-insode-facilities" target="_blank" href="https://t.me/Salokhiddin_Sattarov"> {{ translation.translate ('Possibilities','modul-3') }}</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  -->
            <possibilityComponent class="pb-5" v-for="(possibility, i) in possibilities" :id="i + '-possibility'"
              :possibility="possibility" :index="i" :key="i">
            </possibilityComponent>
          </div>
        </div>
      </div>
    </section>
    <div class="up-btn-container">
      <div id="upbtn" class="up-btn">
        <button @click="up()" class="default-btn" >
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#FFFFFF" height="20px" width="20px" viewBox="0 0 490 490" xml:space="preserve">
<g>
	<polygon points="8.081,242.227 82.05,314.593 199.145,194.882 199.145,490 306.14,490 306.14,210.504 407.949,314.593    481.919,242.227 245.004,0  "/>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
</g>
</svg>

        </button>
      </div>
    </div>
    <mainfooter></mainfooter>
  </div>
</template>


<script defer>
import translate from '../../translation/translate'
import mainbox from './mainbox.vue'
import possibilityComponent from './initialComponents/possibilityComponent.vue'
import mainheader from "./mainheader.vue"
import mainfooter from "./mainfooter.vue"
import ChooseTariffModal from './modal/ChooseTariffModal.vue'

export default {
  name: 'possibilities',
  data() {
    return {
      translation: translate,
      possibilities: [
        {
          title: 'Визуальная часть',
          description: '',
          tabs: [
            {
              width: '80%',
              btn: 'Поэтапное согласование',
              img: 'StepByStepApproval.svg',
              content: 'Устанавливайте последовательный порядок согласования, позволяя пользователям одобрять документ поэтапно, что обеспечивает более структурированный и контролируемый процесс согласования.'
            },
            {
              width: '100%',
              btn: 'ЭЦП при согласования',
              img: 'ETSPVia.svg',
              content: 'Обеспечьте безопасность и подлинность при согласовании документов, используя электронные цифровые ключи для авторизации пользователей и защиты информации'
            },
            {
              width: '90%',
              btn: 'Автоотправка на согласование',
              img: 'AutoSendToApproval.svg',
              content: 'Устанавливайте последовательный порядок согласования, позволяя пользователям одобрять документ поэтапно, что обеспечивает более структурированный и контролируемый процесс согласования.'
            }
          ]
        },
        {
          title: 'Протоколирования действий и история документов',
          description: 'Отслеживайте историю документа от его создания до завершения, включая технические детали каждого шага, чтобы обеспечить полную прозрачность и контроль над рабочим процессом.',
          tabs: [
            {
              width: '100%',
              btn: 'История операций',
              img: 'OperationsHistory.svg',
              content: 'Получите компактный обзор всех значимых шагов, совершенных с документом, без лишней детализации'

            },
            {
              width: '80%',
              btn: 'Протоколирования действий',
              img: 'ActionLogging.svg',
              content: 'Детальная техническая документация о каждом изменении и манипуляции с документом, обеспечивая полную прозрачность и точность в обработке информации'

            }
          ]
        },
        {
          title: 'Хранение электронных документов',
          description: 'Обеспечиваем безопасное и долгосрочное хранение документов в защищенных zip-архивах в более 5 лет, гарантируя сохранность и доступность информации',
          tabs: [
            {
              width: '90%',
              btn: '',
              img: 'DocumentStorage.svg',
              content: 'Данный архив содержит документ и его электронные цифровые подписи, сгенерированные системой юридически значимого электронного документооборота Faktura.uz'
            }
          ]
        },
        {
          title: 'Структурирование организации',
          description: 'Легко управляйте структурой компании, добавляйте филиалы, подразделения,и сотрудников. Гибко настраивайте иерархию и упрощайте процессы в вашей организации через систему ЭДО',
          tabs: [
            {
              width: '100%',
              btn: 'Филиалы',
              img: 'Branches.svg',
              content: 'Клиенты могут управлять списком филиалов, устанавливать уникальные права доступа и упрощать обмен документами между центральным офисом и филиалами, обеспечивая единый и структурированный поток информации.'
            },
            {
              width: '100%',
              btn: 'Подразделения',
              img: 'Subdivisions.svg',
              content: 'Система ЭДО позволяет клиентам гибко создавать и структурировать подразделения организации, устанавливать различные уровни доступа и согласования для оптимизации бизнес-процессов'
            },
            {
              width: '100%',
              btn: 'Сотрудники',
              img: 'Employees.svg',
              content: 'В системе ЭДО клиенты могут легко добавлять и управлять списком сотрудников, предоставляя им отдельные профили с персональными доступами, что обеспечивает более безопасную и удобную работу с документами'
            }
          ]
        },
        {
          title: 'Отчетности',
          description: 'Создание отчетов - усовершенствованная функция, которая предоставляет бухгалтерам гибкие возможности формирования разнообразных отчетов, включая НДС и индивидуальные отчеты.',
          tabs: [
            {
              width: '100%',
              btn: 'Отчет по типам',
              img: 'TypeReport.svg',
              content: 'Отчет по типам реестров позволяет бухгалтерам быстро получить информацию о номерах договоров и электронных счетах-фактурах, что контроль за бухгалтерскими операциями'
            },
            {
              width: '95%',
              btn: 'НДС отчёт',
              img: 'VATReport.svg',
              content: 'Система Faktura EDO позволяет автоматически генерировать точные НДС отчеты на основе отправленных и принятых счет-фактур, облегчая бухгалтерские процессыи обеспечивая соответствие требованиям налоговых органов.'
            },
            {
              width: '100%',
              btn: 'Индивидуальные отчёты',
              img: 'IndividualReports.svg',
              content: 'Клиенты могут управлять списком филиалов, устанавливать уникальные права доступа и упрощать обмен документами между центральным офисом и филиалами, обеспечивая единый и структурированный поток информации.'
            }
          ]
        },
        {
          title: 'Управление реестрами документов',
          description: 'Работа с реестрами разных типов в системе обеспечивает мгновенное создание десятков тысяч документов, улучшая производительность и оптимизируя бухгалтерские операции',
          tabs: [
            {
              width: '100%',
              btn: 'Реестр документов',
              img: 'RegisterOfDocuments.svg',
              content: 'Возможность массово создавать документы, загружая реестры в формате XLS, где каждая строка представляет отдельный документ и обязательные колонки обеспечивают точное и быстрое заполнение документа'
            },
            {
              width: '100%',
              btn: 'Импорт документов',
              img: 'DocumentImport.svg',
              content: 'После заполнения, реестр импортируется в систему, где документы проходят автоматическую проверку. После вашего ознакомления с ними, система предоставляет массовую подпись, позволяя мгновенно подписать и отправить документы контрагенту'
            }
          ]
        },
        {
          title: 'Гибкое управление организациями',
          description: 'Управляйте несколькими организациями в рамках одного аккаунта, переключаясь между ними легко и быстро, а также обменивайтесь документами внутри каждой организации для более эффективного взаимодействия',
          tabs: [
            {
              width: '100%',
              btn: '',
              img: 'OrganizationManagement.svg',
              content: 'Легко добавляйте и переключайтесь между организациями внутри одного аккаунта, обеспечивая удобство управления.'
            }
          ]
        },
        {
          title: 'Персонализированный поиск и фильтрация',
          description: 'Настройте поиск под себя с добавлением меток к документам, сортируйте по типам, статусу, контрагенту, дате и другим параметрам через обширные фильтры системы',
          tabs: [
            {
              width: '100%',
              btn: 'Фильтр',
              img: 'Filter.svg',
              content: 'Фильтруйте документы по типам, ИНН контрагента, статусу, дате и другим параметрам, обеспечивая быстрый и удобный доступ к нужной информации'
            },
            {
              width: '100%',
              btn: 'Метки',
              img: 'Tags.svg',
              content: 'Метки — ваш персональный ярлык для документов. Отмечайте и категоризируйте их по собственному усмотрению, чтобы легко находить и организовывать нужную информацию в системе ЭДО'
            }
          ]
        },
        {
          title: 'Межоператорский роуминг документов',
          description: 'Роуминг документов обеспечивает непрерывный обмен между операторами ЭДО, поддерживая актуальность данных с помощью синхронизации',
          tabs: [
            {
              width: '95%',
              btn: 'Роуминг документы',
              img: 'RoamingDocuments.svg',
              content: 'Свобода выбора оператора не ограничивает вас: межоператорский роуминг обеспечивает обмен документами между системами ЭДО'
            },
            {
              width: '100%',
              btn: 'Синхронизация',
              img: 'Synchronization.svg',
              content: 'Синхронизация с роумингом гарантирует точность данных и бесперебойность электронного документооборота между операторами'
            }
          ]
        },
        {
          title: 'Оповещение',
          description: 'В нашей системе доступна функция уведомления клиента через SMS, Телеграм и электронную почту. Клиент получит уведомления о новых документах, изменении статусов текущих (отправлен, подписан, удален), о процессе согласования и других действиях, связанных с документами',
          tabs: [
            {
              width: '100%',
              btn: '',
              img: 'Alert.svg',
              content: 'Настроить уведомления можно легко через специальные настройки. Вы сами выбираете тип уведомлений, которые соответствуют вашим потребностям, и добавляете свои данные для их активации.'
            }
          ]
        },
        {
          title: 'Массовое подписание',
          description: 'Сэкономьте массу времени и усилий, подписывая сразу большое количество документов всего одним щелчком, благодаря надежной функции Массовое подписание',
          tabs: [
            {
              width: '70%',
              btn: '',
              img: 'BulkSigning.svg',
              content: ''
            }
          ]
        },
        {
          title: 'Прикрепление файлов к документу',
          description: 'Улучшите информативность документов, прикрепив к ним важные файлы, что позволит вашим сообщениям содержать всю необходимую информацию',
          tabs: [
            {
              width: '100%',
              btn: '',
              img: 'AttachingFiles.svg',
              content: 'Вы можете прикрепить различные типы документов, такие как PDF, DOCX, DOC, PNG, WEBP и другие. Просто добавьте свой документ в специальное поле после его создания.'
            }
          ]
        },
        {
          title: 'Маркировка товаров',
          description: 'В системе предоставлены два способа отправки счетов-фактур для маркированных товаров: ручная отправка для небольших объемов и автоматизированная отправка через реестр для крупных заказов.',
          tabs: [
            {
              width: '100%',
              btn: 'Ручной',
              img: 'Manual.svg',
              content: 'Простая отправка счетов-фактур вручную, идеально подходит для небольших объемов товаров, позволяет заполнять данные по каждому товару индивидуально, обеспечивая удобство при обработке небольших заказов'
            },
            {
              width: '100%',
              btn: 'Массовый',
              img: 'Bulk.svg',
              content: 'Эффективная отправка счетов-фактур для больших объемов маркированных товаров через специальный реестр. После загрузки данных о товарах, система автоматически генерирует соответствующие счета-фактуры, упрощая процесс и экономя время'
            }
          ]
        },
        {
          title: 'Мониторинг корпоративных карточек',
          description: 'Система мониторинга расходов по корпоративным картам в Faktura.uz предоставляет полный обзор расходов за выбранный период. Получите подробные отчеты с фискальными чеками, обеспечив контроль бюджета',
          tabs: [
            {
              width: '100%',
              btn: 'Мониторинг карт',
              img: 'MapMonitoring.svg',
              content: 'Наш сервис позволяет добавлять неограниченное количество корпоративных карт и автоматически отслеживать транзакции.'
            },
            {
              width: '100%',
              btn: 'Электронные чеки',
              img: 'ElectronicChecks.svg',
              content: 'Добавляйте электронные чеки для удобного контроля расходов. Детальные отчеты и фискальные чеки обеспечивают прозрачный анализ и бюджетирование'
            },
            {
              width: '100%',
              btn: 'POS терминал',
              img: 'POSTerminal.svg',
              content: 'Мы предлагаем возможность отслеживать транзакции по каждому POS терминалу, мониторить общие продажи и выручку, а также анализировать данные'
            }
          ]
        },
        {
          title: 'Справка - счет фактура',
          description: 'Одним из ключевых процессов, требующих повышенной внимательности, является формирование счетов-фактур. Однако благодаря автоматическому формированию счетов-фактур на основе справок, компании теперь имеют возможность автоматизировать этот процесс и избежать ручного ввода данных.',
          tabs: [
            {
              width: '90%',
              btn: '',
              img: 'HelpInvoice.svg',
              content: ''
            }
          ]
        },
        {
          title: 'Гибридная почта',
          description: 'Сервис, который полностью соответствует реалиям нашего времени. От вас больше не требуется печатать письма, упаковывать их в конверты и отправлять их в почтовом отделении. Наша система предлагает вам простой и легкий способ отправки.',
          tabs: [
            {
              width: '100%',
              btn: '',
              img: 'HybridMail.svg',
              content: 'Сервис, который полностью соответствует реалиям нашего времени. От вас больше не требуется печатать письма, упаковывать их в конверты и отправлять их в почтовом отделении. Наша система предлагает вам простой и легкий способ отправки.'
            }
          ]
        },
        {
          title: 'Архив Менеджер',
          description: 'Быстрый и удобный способ поиска документов без необходимости использования бумажных копий. Вы можете легко получить доступ к документам даже без интернет соединения, что делает систему еще более удобной в использовании.',
          tabs: [
            {
              width: '100%',
              btn: 'Локальный',
              img: 'ArchiveManagerLocal.svg',
              content: 'Локальный метод хранения документов - оптимально для малых организаций с небольшим количеством бухгалтеров, позволяет хранить архивы на компьютере.'
            },
            {
              width: '100%',
              btn: 'Сетевой',
              img: 'ArchiveManagerNetwork.svg',
              content: 'Сетевой метод хранения - для крупных компаний с несколькими бухгалтерами, обеспечивает централизованное хранение архивов на сервере для общего доступа.'
            }
          ]
        },
        {
          title: 'Маршрутизация документов',
          description: 'Гибкая автоматическая маршрутизация документов в системе edo.faktura.uz позволяет направлять документы по различным критериям: ИНН контрагента, должностям, номеру и дате договора.',
          tabs: [
            {
              width: '80%',
              btn: '',
              img: 'DocumentRouting.svg',
              content: ''
            }
          ]
        }
      ],
      redirects: [
        {
          title: "Согласование документов",
          items: [
            {
              title: "Поэтапное согласование",
              url: "0.0"
            },
            {
              title: "ЭЦП при согласования",
              url: "0.1"
            },
            {
              title: "Автоотправка на согласование",
              url: "0.2"
            },
          ]
        },
        {
          title: "Протокол и история документов",
          items: [
            {
              title: "История операций",
              url: "1.0"
            },
            {
              title: "Протоколирования действий",
              url: "1.1"
            }
          ]
        },
        {
          title: "Структурирование организации",
          items: [
            {
              title: "Филиалы",
              url: "3.0"
            },
            {
              title: "Подразделения",
              url: "3.1"
            },
            {
              title: "Сотрудники",
              url: "3.2"
            }
          ]
        },
        {
          title: "Автоматизация бизнес процессов",
          items: [
            {
              title: "Гибридная почта",
              url: "15.0"
            },
            {
              title: "Справка счет фактура",
              url: "14.0"
            },
            {
              title: "Количество мульти-организаций",
              url: "6.0"
            },
            {
              title: "Маршрутизация",
              url: "17.0"
            },
            {
              title: "Оповещение",
              url: "9.0"
            },
            {
              title: "Массовое подписание",
              url: "10.0"
            },
            {
              title: "Хранение электронных документов",
              url: "2.0"
            }
          ]
        },
        {
          title: "Отчетности",
          items: [
            {
              title: "Отчет по типам",
              url: "4.0"
            },
            {
              title: "НДС отчёт",
              url: "4.1"
            },
            {
              title: "Индивидуальные отчёты",
              url: "4.2"
            }
          ]
        },
        {
          title: "Управление реестрами",
          items: [
            {
              title: "Реестр документов",
              url: "5.0"
            },
            {
              title: "Импорт документов",
              url: "5.1"
            }
          ]
        },
        {
          title: "Мониторинг КПК",
          items: [
            {
              title: "Мониторинг карт",
              url: "13.0"
            },
            {
              title: "Электронные чеки",
              url: "13.1"
            },
            {
              title: "POS терминал",
              url: "13.2"
            }
          ]
        },
        {
          title: "Архив Менеджер",
          items: [
            {
              title: "Локальный",
              url: "16.0"
            },
            {
              title: "Сетевой",
              url: "16.1"
            }
          ]
        },
        {
          title: "Роуминг",
          items: [
            {
              title: "Роуминг документы",
              url: "8.0"
            },
            {
              title: "Синхронизация",
              url: "8.1"
            }
          ]
        },
        {
          title: "Фильтрация документов",
          items: [
            {
              title: "Фильтр",
              url: "7.0"
            },
            {
              title: "Метки",
              url: "7.1"
            }
          ]
        },
        {
          title: "Маркировка товаров",
          items: [
            {
              title: "Ручной",
              url: "12.0"
            },
            {
              title: "Массовый",
              url: "12.1"
            }
          ]
        },
      ]
    }
  },
  components: {
    mainheader,
    mainbox,
    mainfooter,
    possibilityComponent,
    ChooseTariffModal
},
  methods: {
    redirectUrl(url) {
      window.scrollTo(0,0);
      let btn = document.getElementById(url)
      if (btn != undefined) {
        btn.click();
      }
      let position = document.getElementById(url.substring(0, url.indexOf('.')) + '-possibility').getBoundingClientRect()
      window.scrollTo({
        top: position.y-140,
        behavior: 'smooth'
      })
    },
    up(){
      window.scrollTo({
        top:0,
        behavior: 'smooth'
      })
    }
  },
  metaInfo() {
    return {
      title: "Преимущества Faktura.EDO",
      meta: [
        { name: 'description', content: 'В системе ЭДО Faktura.EDO есть много возможностей: создание и отправка документов, согласование документов, подразделения и филиалы, внешние модули и многое другое' },
        { name: 'keywords', content: 'Создание и отправка документов,   Согласование документов,  Хранение документов, Фильтрация документов, Подразделения и Филиалы,  Сотрудники в системе, Роуминг, Безопасность,   Протоколирование документа, Внешние модули, Маршрутизация документов, Каналы и протоколы обмена данными, Индивидуальные шаблоны, Управления метками, Мониторинг корпоративных карточек, Массовое подписание документов, Гибридная почта' },
      ]
    }
  },
  mounted() {
    let btn = document.getElementById('upbtn');
    window.addEventListener('scroll', function ()  {
        let y = this.window.scrollY;
        if (y>940) {
          btn.style.right = '110px'
        }else {
          btn.style.right = '-80px'
        }
    })
  }
}
</script>

<style>
.possibility-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.up-btn-container {
  position: fixed;
  bottom: 150px;
  right: -80px;
  z-index: 99;
  transition: all calc(0.1s * 20) ease-in-out;
}

.up-btn {
  position: relative;
}
.up-btn .default-btn {
  padding: 15px;
  border-radius: 10px !important;
}
</style>


