<template>
    <div class="vertical-align-center mt-5">
        <h1>{{ possibility.title }}</h1>
        <div class="article-container mb-5">
            <div class="article-wrapper text-center">
                <span  class="article-text ">{{ possibility.description }}</span>
            </div>
        </div>
        <div class="btn-container mb-5" v-if="possibility.tabs.length > 1"> 
            <button  class="dropbtn article-text article-text-bold" v-for="(tab, i) in possibility.tabs" :key="i" :id="index + '.'+i" :class="(tabindex==i) ? 'article-text mx-2 default-btn' : ' mx-3'" @click="()=>{tabindex = i}"> {{ tab.btn }}</button>
        </div>
        <div style="display:flex; justify-content: center;">
            <img  :style="'width:'+ possibility.tabs[tabindex].width" style="transition: 0.5s;" :src="require(`../../assets/images/svg/possibilities/${possibility.tabs[tabindex].img}`)" />
        </div>
            <p class="mt-5 article-text text-center"> {{ possibility.tabs[tabindex].content }}</p>
        
    </div>
</template>

<script>
import translate from '../../../translation/translate'
export default {
    props: {
        possibility: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            translation: translate,
            tabindex: 0,
        }
    }

}
</script>
<style>
.btn-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.article-wrapper {
    width: 90%;
}
.vertical-align-center{
  position: relative;
}
.vertical-align-center::after{
    display: flex;
    justify-content: center;
    background-color: #72bd49;
    content: "";
    width: 70%;
    height: 2px;
    position: absolute;
    left: 15%;
    bottom: 0px;
}
.article-text{
    font-size: 18px !important;
}
.dropbtn {
    transition: 0.2s;
}
.default-btn {
    padding: 10px 16px;
    margin: 15px 0 !important;
    transition: 0.2s;
}
</style>